import { withAuthenticationRequired } from '@auth0/auth0-react'
import { Container, Box, Typography, styled } from '@material-ui/core'
import { PageProps, Link } from 'gatsby'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MobileNavbar from '../../components/navbar/mobile'
import Navbar from '../../components/navbar'

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  padding: 20px 25px;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0px 5px 10px 0px #eee;
  text-decoration: none;
  font-size: 20px;
`

const StyledActionItem = styled('span')`
  color: #3c3835;
  float: left;
`

const ClearFix = styled('div')`
  clear: both;
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  color: #2e3a59;
  float: right;
`

const StyledTypographyTitle = styled(Typography)`
  color: #008174;
  font-weight: bold;
  margin-bottom: 10px;
`

const StyledTypographySubtitle = styled(Typography)`
  color: #3c3835;
  font-size: 18px;
`

const RegisterPage = (props: PageProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: 945px)` })

  return (
    <>
      {isMobile ? (
        <MobileNavbar bg="#008174" />
      ) : (
        <Navbar path={props.location.pathname} bg="#008174" />
      )}
      <Container
        sx={{
          marginTop: '40px',
        }}
      >
        <Box
          sx={{
            marginBottom: '40px',
          }}
        >
          <StyledTypographyTitle variant="h5">
            Choose your role
          </StyledTypographyTitle>
          <StyledTypographySubtitle>
            I want to register as a...
          </StyledTypographySubtitle>
        </Box>
        <Box>
          <StyledLink to="/register/team/venue">
            <StyledActionItem>Team</StyledActionItem>
            <StyledChevronRightIcon />
            <ClearFix />
          </StyledLink>
          <StyledLink to="/register/player/venue">
            <StyledActionItem>Player</StyledActionItem>
            <StyledChevronRightIcon />
            <ClearFix />
          </StyledLink>
          <StyledLink to="/register/coach/venue">
            <StyledActionItem>Coach / Manager</StyledActionItem>
            <StyledChevronRightIcon />
            <ClearFix />
          </StyledLink>
          <StyledLink to="/register/referee/venue">
            <StyledActionItem>Referee</StyledActionItem>
            <StyledChevronRightIcon />
            <ClearFix />
          </StyledLink>
        </Box>
      </Container>
    </>
  )
}

export default withAuthenticationRequired(RegisterPage)
